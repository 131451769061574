























import { defineComponent, reactive, toRefs, watch } from "@vue/composition-api"
import { WorkBookDto } from "@/utilities/XLSXParser"
import {
    closeDialog,
    DialogButtonType,
    errorDialog,
    infoDialog,
    setMessage,
    successDialog,
} from "@/services/DialogService"
import { parseMissionType } from "@/services/MissionsService"
import { accountCache } from "@/services/UsersClient"
import {
    putMissionTemplate,
    PutMissionTemplateInput,
} from "@/services/MissionTemplatesService"
import {
    ParsingError,
    tryParseInt,
    parseCommaSplitString,
    ParsedMissionTemplate,
    ParsedMissionFieldTemplate,
    checkId,
    openBookFromFile,
    ensureSheetExist,
    ColumnError,
    collectErrorsFromParsedItems,
    KnownParsingError,
} from "@/utilities/Parser"
import { HTTPError } from "ky"
import { WorkBook } from "xlsx"
import {
    MissionFieldTemplateSheetParser,
    MISSION_FIELD_TEMPLATE_SHEET_NAME,
} from "@/utilities/MissionFieldTemplateSheetParser"
import {
    MissionSignFieldTemplateSheetParser,
    MISSION_SIGN_FIELD_TEMPLATE_SHEET_NAME,
    ParsedMissionSignatureFieldTemplate,
} from "@/utilities/MissionSignFieldTemplateSheetParser"

interface MissionTemplateImportRow {
    ID?: string
    類型?: string
    主旨?: string
    描述?: string
    "時長（分鐘）"?: string
    管理人員?: string
    受指派人員?: string
    協作人員?: string
}

const MISSION_TEMPLATE_SHEET_NAME = "任務範本"

export default defineComponent({
    name: "MissionTemplateImporter",
    components: {
        ImporterMissionTable: () => import("./ParsedMissionTemplateTable.vue"),
        ImporterParseErrorTable: () =>
            import("../../components/ImporterParseErrorTable.vue"),
    },
    setup() {
        const state = reactive({
            file: null as File | null,
            items: [] as ParsedMissionTemplate[],
            errors: [] as ParsingError[],
        })

        async function parseFile(file: File) {
            try {
                infoDialog("解析中...", "", DialogButtonType.None)
                const book = await openBookFromFile(file)
                ensureSheetsExist(book.book)
                const sheetParser = new MissionFieldTemplateSheetParser()
                const fields = await sheetParser.parse(book)
                const signFieldParser =
                    new MissionSignFieldTemplateSheetParser()
                const signFields = await signFieldParser.parse(book)
                const templates = await parseMissionFromBook(
                    book,
                    fields,
                    signFields
                )
                const fieldErrors = collectErrorsFromParsedItems(fields)
                const signFieldErrors = collectErrorsFromParsedItems(signFields)
                const templateErrors = collectErrorsFromParsedItems(templates)

                state.items = templates
                state.errors = templateErrors
                    .concat(fieldErrors)
                    .concat(signFieldErrors)

                closeDialog()
                if (state.errors.length) {
                    errorDialog("解析發生錯誤", "請檢查匯入格式或資料是否正確")
                    return
                }
                successDialog("解析完成")
            } catch (error) {
                closeDialog()
                const msg =
                    error instanceof KnownParsingError
                        ? error.message
                        : "解析過程發生不明錯誤"

                errorDialog(msg)
            }
        }

        function ensureSheetsExist(book: WorkBook): void {
            const sheets = [
                MISSION_TEMPLATE_SHEET_NAME,
                MISSION_FIELD_TEMPLATE_SHEET_NAME,
                MISSION_SIGN_FIELD_TEMPLATE_SHEET_NAME,
            ]
            sheets.forEach((sheetName) => ensureSheetExist(book, sheetName))
        }

        async function parseMissionFromBook(
            book: WorkBookDto,
            fields: ParsedMissionFieldTemplate[],
            signFields: ParsedMissionSignatureFieldTemplate[]
        ) {
            const templates = parseBasicInfoFromBook(book)

            //建立人員對照表
            await matchUsers(templates)

            //對照工項欄位與簽名欄位
            templates.forEach((missionTemplate) => {
                missionTemplate.missionFieldTemplates = fields.filter(
                    (row) => row.missionTemplateId == missionTemplate.id
                )
                missionTemplate.signatureFieldTemplates = signFields.filter(
                    (i) => i.missionTemplateId == missionTemplate.id
                )
            })

            return templates
        }

        watch(
            () => state.file,
            async (file) => {
                state.items = []
                state.errors = []
                if (file) {
                    parseFile(file)
                }
            }
        )

        async function matchUsers(templates: ParsedMissionTemplate[]) {
            const templateRelateUserIds = templates.flatMap((item) =>
                item.managers.concat(item.cooperators).concat(item.assignees)
            )
            await accountCache.updateByIds(templateRelateUserIds, true)

            templates.forEach((item) => {
                function matching(
                    accounts: string[],
                    colName: string
                ): string[] {
                    const matches = accounts.map((account) => ({
                        account,
                        id: accountCache.get(account)?.id,
                    }))
                    const notMatchedAccounts = matches
                        .filter((m) => !m.id)
                        .map((m) => m.account)
                    if (notMatchedAccounts.length) {
                        item.errors.push({
                            col: colName,
                            err: `${notMatchedAccounts.join(
                                ", "
                            )} 無法對照到帳號`,
                        })
                        return []
                    }
                    return matches.map((m) => m.id!)
                }

                item.managerIds = matching(item.managers, "管理人員")
                item.cooperatorIds = matching(item.cooperators, "協作人員")
                item.assigneeIds = matching(item.assignees, "受指派人員")
            })
        }

        function parseBasicInfoFromBook(book: WorkBookDto) {
            return book
                .getSheetByName<MissionTemplateImportRow>(
                    MISSION_TEMPLATE_SHEET_NAME,
                    {
                        raw: false,
                    }
                )
                .map((row, rowIndex) => {
                    const errors = [] as ColumnError[]
                    function addError(col: string, err: string) {
                        errors.push({
                            col,
                            err,
                        })
                    }

                    if (!row.ID) addError("ID", "缺漏 ID")
                    else if (!checkId(row.ID))
                        addError("ID", "ID 必須為英文數字底線減號的組合")
                    if (!row.類型) addError("類型", "缺漏 類型")

                    const type = parseMissionType(row.類型 ?? "")

                    if (type === null)
                        addError(
                            "類型",
                            `類型對照失敗，必須為"巡檢","維修","保養"`
                        )

                    if (!row.主旨) addError("主旨", `缺漏 主旨`)
                    const duration = tryParseInt(row["時長（分鐘）"] ?? "")

                    const managers = parseCommaSplitString(row.管理人員 ?? "")

                    const cooperators = parseCommaSplitString(
                        row.協作人員 ?? ""
                    )

                    const assignees = parseCommaSplitString(
                        row.受指派人員 ?? ""
                    )
                    const result: ParsedMissionTemplate = {
                        sheet: MISSION_TEMPLATE_SHEET_NAME,
                        sourceRow: rowIndex + 2,
                        id: row.ID,
                        type,
                        subject: row.主旨,
                        description: row.描述 ?? "",
                        duration,
                        managers,
                        cooperators,
                        assignees,
                        missionFieldTemplates: [],
                        signatureFieldTemplates: [],
                        errors,
                    }
                    return result
                })
        }

        async function startImport() {
            if (state.errors.length) {
                errorDialog("尚未處理解析錯誤，無法匯入")
                return
            }
            infoDialog("匯入中，請稍後", "", DialogButtonType.None)
            for (let i = 0; i < state.items.length; i++) {
                const item = state.items[i]
                try {
                    const input: PutMissionTemplateInput = {
                        id: item.id,
                        type: item.type!,
                        subject: item.subject!,
                        description: item.description,
                        duration: item.duration!,
                        managerIds: item.managerIds,
                        cooperatorIds: item.cooperatorIds,
                        assigneeIds: item.assigneeIds,
                        missionFieldTemplates: item.missionFieldTemplates.map(
                            (field) => ({
                                sortIndex: field.sortIndex!,
                                spaceObjectId: field.spaceObjectId,
                                subject: field.subject!,
                                description: field.description!,
                                type: field.type!,
                                selectOptions: field.selectOptions,
                                defaultValue: field.defaultValue,
                            })
                        ),
                        missionSignatureFieldTemplates:
                            item.signatureFieldTemplates.map((t) => ({
                                title: t.title!,
                                acceptSignerIdentity: t.acceptSignerId!,
                            })),
                    }
                    await putMissionTemplate(input)
                    setMessage(`${i} / ${state.items.length}`)
                } catch (error) {
                    closeDialog()

                    if (error instanceof HTTPError) {
                        if (
                            error.response.status >= 400 ||
                            error.response.status < 500
                        ) {
                            errorDialog(
                                `${item.sourceRow}列 匯入發生錯誤，請檢查匯入資料`,
                                `${await error.response.text()}`
                            )
                            return
                        }
                    }
                    errorDialog(
                        `${item.sourceRow}列 匯入發生不預期錯誤，請聯絡系統維護人員`,
                        `${error}`
                    )
                    return
                }
            }
            closeDialog()
            successDialog("匯入完成")
        }

        return {
            ...toRefs(state),
            startImport,
        }
    },
})
