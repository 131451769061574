import { accountCache } from "@/services/UsersClient"
import { ParsedItem } from "./Parser"
import { SheetParser } from "./SheetParser"

export class MissionSignFieldTemplateSheetParser extends SheetParser<
    MissionFieldTemplateImportRow,
    ParsedMissionSignatureFieldTemplate
> {
    sheetName = MISSION_SIGN_FIELD_TEMPLATE_SHEET_NAME
    parseRow(
        row: MissionFieldTemplateImportRow,
        payload: ParsedItem,
        addError: (col: string, err: string) => void
    ): ParsedMissionSignatureFieldTemplate {
        if (!row.任務範本ID) addError("任務範本ID", "缺漏 任務範本ID")
        if (!row.接受的簽名身份)
            addError("接受的簽名身份", "缺漏 接受的簽名身份")
        if (!row.簽名頭銜) addError("簽名頭銜", "缺漏 簽名頭銜")

        return Object.assign(payload, {
            missionTemplateId: row.任務範本ID,
            title: row.簽名頭銜,
            acceptSignerAccount: row.接受的簽名身份,
            acceptSigner: null,
        })
    }

    async postProcess(
        parsedItems: ParsedMissionSignatureFieldTemplate[]
    ): Promise<ParsedMissionSignatureFieldTemplate[]> {
        await accountCache.updateByIds(
            parsedItems
                .map((i) => i.acceptSignerAccount)
                .filter((id) => !!id) as string[]
        )

        parsedItems.forEach((row) => {
            if (!row.acceptSignerAccount) return

            const found = accountCache.get(row.acceptSignerAccount)
            if (!found)
                row.errors.push({
                    col: "接受的簽名身份",
                    err: "無法對照到帳號",
                })
            else {
                row.acceptSigner = found.name
                row.acceptSignerId = found.id
            }
        })

        return parsedItems
    }
}

interface MissionFieldTemplateImportRow {
    任務範本ID?: string
    簽名頭銜?: string
    接受的簽名身份?: string
}

export interface ParsedMissionSignatureFieldTemplate extends ParsedItem {
    missionTemplateId?: string
    title?: string | null
    acceptSigner: string | null
    acceptSignerAccount?: string | null
    acceptSignerId?: string | null
}

export const MISSION_SIGN_FIELD_TEMPLATE_SHEET_NAME = "任務簽名欄範本"
