import {
    FieldType,
    FieldValue,
    parseFieldType,
    parseNormalAbnormal,
    vaildFieldTypeText,
} from "@/services/MissionTemplatesService"
import { SpaceObjectCache } from "@/services/SpaceObjectsService"
import {
    parseCommaSplitString,
    ParsedItem,
    ParsedMissionFieldTemplate,
    tryParseFloat,
    tryParseInt,
} from "./Parser"
import { SheetParser } from "./SheetParser"

export class MissionFieldTemplateSheetParser extends SheetParser<
    MissionFieldTemplateImportRow,
    ParsedMissionFieldTemplate
> {
    sheetName = MISSION_FIELD_TEMPLATE_SHEET_NAME
    fieldKeySet = new Set<string>()

    parseRow(
        row: MissionFieldTemplateImportRow,
        payload: ParsedItem,
        addError: (col: string, err: string) => void
    ): ParsedMissionFieldTemplate {
        if (!row.任務範本ID) addError("任務範本ID", "缺漏 任務範本ID")
        if (!row.工項順序) addError("工項順序", "缺漏 工項順序")
        const sortIndex = tryParseInt(row.工項順序!)
        if (sortIndex === null) addError("工項順序", "必須是整數")
        const fieldKey = `${row.任務範本ID}:${sortIndex}`
        if (this.fieldKeySet.has(fieldKey)) addError("工項順序", "發生重複")
        else this.fieldKeySet.add(fieldKey)

        if (!row.主旨) addError("主旨", "缺漏 主旨")
        if (!row.工項類型) addError("工項類型", "缺漏 工項類型")

        const type = parseFieldType(row.工項類型 ?? "")
        if (type === null)
            addError(
                "工項類型",
                `工項類型對照失敗，必須為${vaildFieldTypeText.join(", ")}`
            )
        const selectOptions = parseCommaSplitString(row.選擇選項 ?? "")
        let defaultValue = null as FieldValue
        if (row.預設值) {
            switch (type) {
                case FieldType.NormalAbnormal:
                    defaultValue = parseNormalAbnormal(row.預設值)
                    if (!defaultValue)
                        addError(
                            "預設值",
                            `當工項類型為"正常異常"時，預設值必須為 "正常","異常"`
                        )
                    break
                case FieldType.Number:
                    defaultValue = tryParseFloat(row.預設值)
                    if (defaultValue === null)
                        addError(
                            "預設值",
                            `當工項類型為"數值"時，預設值必須為數值`
                        )
                    break
                case FieldType.Select:
                    if (!selectOptions.includes(row.預設值))
                        addError(
                            "預設值",
                            `當工項類型為"選擇"時，預設值必須是選擇選項之一`
                        )
                    else defaultValue = row.預設值!
                    break
                case FieldType.YesNo:
                    defaultValue = (() => {
                        switch (row.預設值) {
                            case "是":
                                return true
                            case "否":
                                return false
                            default:
                                addError(
                                    "預設值",
                                    `當工項類型為"是否"時，預設值必須是為 "是","否"`
                                )
                                return null
                        }
                    })()
                    break
                case FieldType.Text:
                case FieldType.MutilineText:
                    //文字與多行文字不檢查預設值
                    defaultValue = row.預設值 ?? "未填寫"
                    break
                default:
                    addError("預設值", `判斷工項類型失敗導致無法檢查預設值`)
                    break
            }
        } else addError("預設值", `缺漏 預設值`)

        return Object.assign(payload, {
            missionTemplateId: row.任務範本ID,
            sortIndex,
            spaceObjectId: row.空間物件ID,
            subject: row.主旨,
            description: row.描述 ?? "",
            type,
            selectOptions,
            defaultValue,
        })
    }

    async postProcess(
        parsedItems: ParsedMissionFieldTemplate[]
    ): Promise<ParsedMissionFieldTemplate[]> {
        await SpaceObjectCache.updateByIds(
            parsedItems
                .map((row) => row.spaceObjectId)
                .filter((id) => !!id) as string[]
        )

        parsedItems.forEach((row) => {
            if (!row.spaceObjectId) return

            const found = SpaceObjectCache.get(row.spaceObjectId)
            if (!found)
                row.errors.push({
                    col: "空間物件ID",
                    err: `找不到 ${row.spaceObjectId} 空間物件`,
                })
            else row.spaceObject = `${found.name} (${found.number})`
        })

        return parsedItems
    }
}

interface MissionFieldTemplateImportRow {
    任務範本ID?: string
    工項順序?: string
    空間物件ID?: string
    主旨?: string
    描述?: string
    工項類型?: string
    選擇選項?: string
    預設值?: string
}

export const MISSION_FIELD_TEMPLATE_SHEET_NAME = "任務工項範本"
