import { ColumnError, ensureSheetExist, ParsedItem } from "./Parser"
import { WorkBookDto } from "./XLSXParser"

export abstract class SheetParser<TImportRow, TParsedItem extends ParsedItem> {
    abstract readonly sheetName: string

    async parse(book: WorkBookDto): Promise<TParsedItem[]> {
        ensureSheetExist(book.book, this.sheetName)
        const parsedItems = book
            .getSheetByName<TImportRow>(this.sheetName, { raw: false })
            .map((row, rowIndex) => {
                const errors = [] as ColumnError[]
                function addError(col: string, err: string) {
                    errors.push({ col, err })
                }
                const payload: ParsedItem = {
                    sheet: this.sheetName,
                    sourceRow: rowIndex + 2,
                    errors: errors,
                }
                return this.parseRow(row, payload, addError)
            })

        return await this.postProcess(parsedItems)
    }

    abstract parseRow(
        row: TImportRow,
        payload: ParsedItem,
        addError: (col: string, err: string) => void
    ): TParsedItem

    async postProcess(parsedItems: TParsedItem[]): Promise<TParsedItem[]> {
        return parsedItems
    }
}
